<template>
  <b-col>
    <b-table id="table" striped hover :items="rowsShow" :fields="fields">
      <template #cell()="data">
        <b-link class="mr-4" target="_blank" :to="{ name: 'Source', params: {id: data.item.id}}">{{data.value}}</b-link>
      </template>
    </b-table>
  </b-col>
</template>

<script>

export default {
  name: 'SourcesAdmin',
  components: {
  },
  computed: {
    rowsShow () {
      return this.$store.state.rowsShow
    }
  },
  created: function () {
    document.title = "Sources admin"
    this.$store.commit('setBrand', 'Regulatory Horizon')
    this.$stat.log({ page: 'sources admin', action: 'open sources admin' })
  },
  data () {
    const data = {
      fields: [
        { key: 'id', sortable: true },
        { key: 'source', sortable: true },
        { key: 'publisher', sortable: true },
        { key: 'countryName', sortable: true },
        { key: 'maxdate', sortable: true },
        { key: 'lastSuccess', sortable: true },
        { key: 'frequency', sortable: true },
        { key: 'age', sortable: true },
        { key: 'ageAlert', sortable: true },
        { key: 'ok', sortable: true },
        { key: 'broken', sortable: true },
        { key: 'client', sortable: true },
        { key: 'parser', sortable: true }
      ]
    }
    return data
  },
  methods: {
  }
}
</script>

<style>
</style>
